import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useLocale } from "../locale";
import { Resources, ResourcesVariables } from "./__generated__/Resources";
import { Locale } from "../../../../../__generated__/globalTypes";
import {
  ResourceSections,
  ResourceSectionsVariables,
} from "./__generated__/ResourceSections";

const ResourcesQuery = gql`
  query Resources($token: String!, $locale: Locale!, $sectionId: String) {
    resources(token: $token, locale: $locale, sectionId: $sectionId) {
      result {
        title
        subtitle
        section {
          id
          label
        }
        image
        file
      }
      error {
        message
        code
      }
    }
  }
`;

export const useResources = (sectionId?: string) => {
  const token = useUserToken();
  const locale = useLocale();
  const { data, ...res } = useQuery<Resources, ResourcesVariables>(
    ResourcesQuery,
    {
      skip: !token,
      variables: {
        token,
        locale: Locale[locale],
        sectionId,
      },
      fetchPolicy: "cache-and-network",
    },
  );

  return {
    ...res,
    data,
    items: data?.resources.result || [],
  };
};

const ResourceSectionsQuery = gql`
  query ResourceSections($token: String!, $locale: Locale!) {
    resourceSections(token: $token, locale: $locale) {
      result {
        id
        label
      }
      error {
        message
        code
      }
    }
  }
`;

export const useResourceSections = () => {
  const token = useUserToken();
  const locale = useLocale();
  const { data, ...res } = useQuery<
    ResourceSections,
    ResourceSectionsVariables
  >(ResourceSectionsQuery, {
    skip: !token,
    variables: {
      token,
      locale: Locale[locale],
    },
  });

  return {
    ...res,
    data,
    items: data?.resourceSections.result || [],
  };
};
