import {
  FiltersBar,
  FiltersBarInput,
  FiltersBarSelect,
  IconMagnify16,
  ResourcesList,
  Wrapper,
  Loader,
} from "@royalcanin-be-partner-portal/ui-kit";
import React, { useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Layout } from "../components/Layout";
import { useResources, useResourceSections } from "../lib/hooks/useResources";
import { ResourceSections_resourceSections_result } from "../lib/hooks/__generated__/ResourceSections";
import { CheckPermission } from "../components/CheckPermission";
import { withPage } from "../lib/withPage";

const messages = defineMessages({
  search: {
    id: "website.resources.filters.search",
    defaultMessage: "Que cherchez-vous ?",
  },
  allSections: {
    id: "resources.label.allSections",
    defaultMessage: "Toutes les sections",
  },
});

const Resources = () => {
  const { formatMessage } = useIntl();
  const [currentSection, setCurrentSection] = useState<
    ResourceSections_resourceSections_result | undefined
  >();
  const [search, setSearch] = useState("");
  const { items: resources, loading: loadingResources } = useResources(
    (currentSection && currentSection.id) || undefined,
  );
  const { items: sections, loading: loadingSections } = useResourceSections();
  const loading = loadingResources || loadingSections;

  const resourcesFiltered = resources.filter(resource => {
    if (!search) {
      return true;
    }

    const rgx = new RegExp(`${search.trim()}`, "i");
    return rgx.test(resource.title);
  });

  const resourcesSectionIds = resources
    .map(resource => resource?.section?.id || null)
    .filter(Boolean) as string[];

  const sectionsFiltered = sections.filter(
    section => resourcesSectionIds.indexOf(section.id) >= 0,
  );

  return (
    <Wrapper>
      <FiltersBar>
        <FiltersBarSelect
          label={
            <FormattedMessage
              id="website.resources.filters.section"
              defaultMessage="Section"
            />
          }
          items={[
            {
              label: formatMessage(messages.allSections),
              value: "all",
            },
            ...(!loading
              ? sectionsFiltered.map(section => ({
                  label: section.label,
                  value: section.id,
                }))
              : []),
          ]}
          name="sectionId"
          value={(currentSection && currentSection.id) || "all"}
          onChange={e => {
            const section = sections.find(s => s.id === e.target.value);
            setCurrentSection(section);
          }}
        />
        <FiltersBarInput
          IconComponent={IconMagnify16}
          placeholder={formatMessage(messages.search)}
          value={search}
          onChange={e => setSearch(e.currentTarget.value)}
        />
      </FiltersBar>
      {!!loadingResources ? (
        <Loader />
      ) : (
        <ResourcesList
          resources={resourcesFiltered.map(resource => ({
            title: resource.title,
            subtitle: resource.subtitle || undefined,
            sectionName: resource.section?.label || "",
            link: resource.file || "",
            imageUrl: resource.image || "",
          }))}
        />
      )}
    </Wrapper>
  );
};

const ResourcesPage = () => (
  <Layout>
    <CheckPermission permissions={"resources"} redirect>
      <Resources />
    </CheckPermission>
  </Layout>
);

export default withPage(ResourcesPage);
